import React, { useState, useEffect, useContext } from 'react';
import './FBFooter.css';
import $ from 'jquery'; 
import axiosInstance from '../../../axiosInstance';
import { UserContext } from '../../../UserContext';

const API_URL = process.env.REACT_APP_API_URL;
const STARS_API = `${API_URL}/api/stars`;
const DOWNLOAD = `${API_URL}/api/convert/download`; // New API endpoint for logging downloads


const FBFooter = ({ pdfUrl, flipbookRef, googleDriveID, addStickyNote, pages, currentPage,  totalPages,  toggleSidebar, sidebarVisible, soundEnabled, toggleSound  }) => {
  const [stars, setStars] = useState(0);
  const [showStarRating, setShowStarRating] = useState(false);
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false); 
  const { user } = useContext(UserContext);

  const handlePrevPage = () => {
    const flipbook = $(flipbookRef.current);
    const prevPage = flipbook.turn('page') - 1;
    if (prevPage >= 1) {
      flipbook.turn('previous');
    } else {
      console.warn('Already on the first page.');
    }
  };

  const handleNextPage = () => {
    const flipbook = $(flipbookRef.current);
    const nextPage = flipbook.turn('page') + 1;
    if (nextPage <= pages.length) {
      flipbook.turn('next');
    } else {
      console.warn('Already on the last page.');
    }
  };

  
  const handleDownload = async () => {
    try {
      // Retrieve the PDF URL from state or session storage (whichever is available)
      const storedPdfUrl = pdfUrl || sessionStorage.getItem('googleDriveLink');
  
      if (!storedPdfUrl) {
        console.error('No PDF URL available for download.');
        return;
      }
  
      // Fetch the file as a blob (if needed) or directly use the URL
      const response = await fetch(storedPdfUrl);
      const blob = await response.blob(); // Convert the response to blob
  
      // Create a temporary link for the download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob); // Create a URL for the blob
  
      link.href = url;
      link.download = `${user.email}.pdf`; // Set the download attribute with the file name
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Cleanup: Remove the link and revoke the URL object
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the created object URL
  
      // Log the download action after successful download
      if (user && user.email&& googleDriveID) {
        await logDownload(googleDriveID, user.email);
      } else {
        console.error('User email is not available.');
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };
  
  
  
  const logDownload = async (googleDriveID, email) => {
    const downloadData = {
      email: email,
      googleDriveID: googleDriveID, 
      timestamp: new Date().toISOString(),
    };
  
    try {
      await axiosInstance.post(`${DOWNLOAD}`, downloadData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error logging download:', error);
      throw error;
    }
  };

  const handleStarClick = async (index) => {
    if (isRatingSubmitted) return;

    const rating = index + 1;
    setStars(rating);

    try {
      const response = await sendStarRating(rating);

      if (response.status === 201 || response.status === 200) {
        setIsRatingSubmitted(true);
        setShowStarRating(false); 
      }
    } catch (error) {
      console.error('Error sending rating:', error);
    }
  };

  const sendStarRating = async (rating) => {
    if (!user || !user.email) {
      console.error('User email is not available.');
      return;
    }

    const ratingData = {
      email: user.email,
      pdfUrl: pdfUrl,
      rating: rating,
      date: new Date().toISOString(),
    };

    try {
      return await axiosInstance.post(STARS_API, ratingData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error sending rating data:', error);
      throw error;
    }
  };

  const pageRangeStart = currentPage;
  const pageRangeEnd = Math.min(currentPage + 1, totalPages);

  return (
    <div className="flipbook-footer">
      <button onClick={handleDownload}><i className="fas fa-download"></i></button>
      <button onClick={toggleSidebar}>
      {sidebarVisible ? '' : ''}<i className="fa-solid fa-table"></i></button>
      <button onClick={addStickyNote}><i className="fa-solid fa-notes-medical" /></button>
      <button onClick={handlePrevPage}><i className="fa-solid fa-backward"></i></button>
      <button onClick={handleNextPage}><i className="fa-solid fa-forward"></i></button>
      

      <button onClick={() => setShowStarRating(!showStarRating)}>
        <i className={`fas fa-star ${stars > 0 ? 'selected' : ''}`}></i>
      </button>

      {showStarRating && (
        <div className="star-rating">
          {[...Array(5)].map((_, index) => (
            <i
              key={index}
              className={`fas fa-star ${index < stars ? 'selected' : ''}`}
              onClick={() => handleStarClick(index)}
              style={{ pointerEvents: isRatingSubmitted ? 'none' : 'auto' }} 
            />
          ))}
        </div>
      )}

      <div className="page-count">
      {pageRangeStart}-{pageRangeEnd} of {totalPages}
      </div>
    </div>
  );
};

export default FBFooter;
