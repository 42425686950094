// AboutUs.jsx
import React from 'react';
import './AboutUs.css';
import Header from '../Header/Header';
import abstractSvg from '../Assets/abstract.svg';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate();

    const handleExploreClick = () => {
        navigate('/home'); // Adjust the path as needed
    };

    return (
        <div>
            <Header />
            <div className="stripe"></div>
            <div className="about-us-container">
                <div className="about-us-text">
                    <h1>Redefining What's Possible</h1>
                    <p>At Contranorm Media & Production, we prioritize a solution-based approach; we’re driven by innovation, guided by creativity, and committed to redefining what’s possible. That's why for the last eighteen years, we've been empowering businesses and individuals, like you, with creative solutions to help navigate diverse professional landscapes. By leveraging our access to information, skilled writers, independent creators, and cutting-edge technologies, you’ll not only forge a path full of potential, but you'll leave a lasting impression along the way.</p>
                    <p>So, if you believe that a skilled writer, independent creator, or professional advocate can help you overcome a minor setback, so you can make greater impact, then let us help you create the change you want to see.</p>
                </div>
                <button className="explore" onClick={handleExploreClick}>
                    <span>Explore</span>
                </button>
                <div className="about-us-image-container">
                    <div className="about-us-image">
                        <img src={abstractSvg} alt="Abstract" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
