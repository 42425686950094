import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL:', API_URL);


const NavMessage = () => {
  
  const [message, setMessage] = useState([]);
  const [error, setError] = useState('');
 


  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/data`); // Adjust URL as needed
        const data = response.data;
        const newOrderMessage = data.newOrder || [];
        setMessage(newOrderMessage);
      } catch (error) {
        console.error('Error fetching message:', error);
        setError('Failed to fetch messages');
      }
    };

    fetchMessage();
    const intervalId = setInterval(fetchMessage, 900000); // Fetch every 10 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleDelete = async (messageId) => {
    try {
      await axios.delete(`${API_URL}/api/data/delete/${messageId}`);
      setMessage(message.filter(message => message._id !== messageId));
    } catch (error) {
      console.error('Error deleting message:', error);
      setError('Failed to delete message');
    }
  };

  return (
    <li className="nav-item dropdown">
      <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        <i className="bi bi-chat-left-text"></i>
        <span className="badge bg-primary badge-number">{message.length}</span>
      </a>
      <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
        {error && <div className="dropdown-header text-danger">{error}</div>}
        {message.length === 0 ? (
          <div className="dropdown-header">No new messages</div>
        ) : (
          message.map((msg, index) => (
            <React.Fragment key={msg._id}>
              <div className="dropdown-item d-flex align-items-center message-item">
               <div>
                  <h4>{msg.email}</h4>
                  <p>
                    <span style={{ fontWeight: 'normal', color: '#444' }}>Project:</span> {msg.documentType}
                  </p>
                  <p>
                    <span style={{ fontWeight: 'normal', color: '#444' }}>Due in:</span> {msg.deliveryTurnaround}
                  </p>
                  <p>
                    <span style={{ fontWeight: 'normal', color: '#444' }}>Services:</span> {Array.isArray(msg.additionalServices) ? msg.additionalServices.join(', ') : 'None'}
                  </p>
                  <p>
                    <span style={{ fontWeight: 'normal', color: '#444' }}>Coupon Code:</span> {msg.couponCode}
                  </p>
                  <p className="align-right">${msg.total}</p>
                </div>
                <button onClick={() => handleDelete(msg._id)} className="btn btn-danger btn-sm">
                  Delete
                </button>
              </div>
              {index < message.length - 1 && <hr className="message-divider" />} {/* Divider between messages */}
            </React.Fragment>
          ))
        )}
      </div>
    </li>
  );
};

export default NavMessage;
