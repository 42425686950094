import React from 'react';
import './SideBar.css';
import NavItem from './NavItem';
import navList from '../DataStatic/navList';

function SideBar({ onNavClick }) {
  const handleNavClick = (name) => {
    onNavClick(name);
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {navList.map(nav => (
          <NavItem key={nav._id} nav={nav} onClick={() => handleNavClick(nav.name)} />
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;
