import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; // Ensure this path is correct
import './Hook.css'; // Import CSS for PrivacyComponent if needed

const HookC = () => {
  const [action, setAction] = useState("Document Preparation"); // Initial state is "Document Preparation"
  const navigate = useNavigate(); // Use navigate for navigation

  const handleHomeClick = () => {
    navigate('/home'); // Navigate to home page
  };

  const handleDocumentPreparationClick = () => {
    setAction("Document Preparation"); // Set action state to "Document Preparation" when clicked
  };

  const handleConfidenceClick = () => {
    setAction("Confidence"); // Set action state to "Confidence" when clicked
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Document Preparation" ? "Document Preparation" : "Confidence"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page"><div className="header">
        <div className="text">{action === "Document Preparation" ? "Premium Services" : "Premium Services"}</div>
        <div className="underline"></div>
      </div>
      
      <>
        {action === "Document Preparation" ? (
          <>
          <br /><br /><br />
             <h4>Feeling Undervalued or Unheard?</h4><br />
         
            <p> You are  your most valuable asset, and you must honor yourself at all costs, so if you find yourself in a challenging situation and are unsure of how to respond, use Contranorm Media & Production's premium ghostwriting service. From the revision to submission, we've got your back.
              <br /><br />

              Let us help you transcend your current circumstance and leverage the power of focused action through proper planning and skilled communication. </p>
          </>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <br /><br /><br />
            <h4>We Do the Work for You.</h4><br />
            
        
          <p>  
            First, submit a project request. Then, gather your documentation and upload it to our servers. Be sure to provide clear and complete instructions. Finally, pay the initial quote. </p>
            <p>After that, we'll take over and do much of the busy work for you, so you don't have to.
            <br /><br />Prior to your project deadline, be sure to login to your account to view your project,  respond to any questions that we may have, and provide feedback before your order closes.


            </p>
         
            
            <br />
            
          </div>
        )}
      </>
      <div className="submit-container">
        {action === "Document Preparation" ? (
          <>
            <div className="submit" onClick={handleHomeClick}>Go Back</div>
            <div className="submit gray" onClick={handleConfidenceClick}>Learn More</div>
          </>
        ) : (
          <>
            <div className="submit gray" onClick={handleDocumentPreparationClick}>Go Back</div>
            <Link to="/price-calculator" className="submit">Get Started</Link>
          </>
        )}
      </div>
      </div>
      
      
      
    </Mount>
  );
};

export default HookC;
