import React, { useState, useEffect } from 'react';
import './FlipBookSidebar.css';

const FlipBookSidebar = ({ pages, activeThumbnail, handleThumbnailClick, sidebarVisible, toggleSidebar, sidebarContent, calculateHorizontalCenteringStyles }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 535);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 535);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={`flipBookSidebar ${sidebarVisible ? 'visible' : 'invisible'} ${isSmallScreen ? 'horizontal' : ''}`}
    >
      {sidebarContent === 'thumbnails' && (
        <div className="flipbookSidebarContent">
          {pages.map((src, index) => (
            <div
              key={index}
              className={`thumbnail ${activeThumbnail === index ? 'active' : ''}`}
              onClick={() => handleThumbnailClick(index)}
            >
              <img src={src} alt={`Thumbnail ${index + 1}`} />
              <div className="page-number">{index + 1}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FlipBookSidebar;
