// src/axiosInstance.js
import axios from 'axios';

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL:process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// src/axiosInstance.js
instance.interceptors.response.use(
  (response) => {
    const contentType = response.headers['content-type'];

    if (contentType && contentType.includes('text/html')) {
      console.log('Interceptor detected HTML response');
      // Explicitly throw an error to catch it in the component
      return Promise.reject({ message: 'Session expired', sessionExpired: true });
    }

    return response; // Return the response if it's not HTML
  },
  (error) => {
    if (error.response && error.response.status === 410) {
      console.error('Interceptor detected 410 Gone response');
      // Handle the 410 response, e.g., notify the user the file is gone
      return Promise.reject({ message: 'File no longer available', fileGone: true });
    }

    // Handle other errors
    console.error('Interceptor error:', error);
    return Promise.reject(error);
  }
);



export default instance;
