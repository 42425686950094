import axios from 'axios';

const TestComponent = () => {
  const sendTestRequest = async () => {
    try {
      const response = await axios.post('http://localhost:8081/users/test');
      console.log('Response from backend:', response.data);
    } catch (error) {
      console.error('Error making request:', error);
    }
  };

  return (
    <div>
      <button onClick={sendTestRequest}>Send Test Request</button>
    </div>
  );
};

export default TestComponent;
