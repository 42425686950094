import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactsBanner.css';

const API_URL = process.env.REACT_APP_API_URL;

const ContactsBanner = ({ onClose }) => {
  const [currentView, setCurrentView] = useState('search');
  const [searchEmail, setSearchEmail] = useState('');
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/contact`);
      console.log('Contacts response:', response.data);
      const data = response.data;
      setContacts(Array.isArray(data) ? data : []); // Ensure contacts is an array
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setContacts([]);
    }
    setLoading(false);
  };

  const fetchContactByEmail = async (email) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/contact`, {
        params: { email }
      });
      console.log('Contact response:', response.data); 
      setSelectedContact(response.data);
    } catch (error) {
      console.error('Error fetching contact:', error);
      setSelectedContact(null);
    }
    setLoading(false);
  };

  const handleSearchClick = () => {
    setCurrentView('search');
    fetchContacts();
  };

  const handleViewClick = () => {
    setCurrentView('view');
    setSelectedContact(null); // Reset selected contact
  };

  const handleViewContact = async () => {
    if (searchEmail.trim()) {
      await fetchContactByEmail(searchEmail);
    } else {
      console.error('Email is required to view a contact');
    }
  };

  useEffect(() => {
    if (currentView === 'search') {
      fetchContacts();
    }
  }, [currentView]);

  return (
    <div className="contacts-banner">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>x</button>
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${currentView === 'search' ? 'active' : ''}`}
            onClick={handleSearchClick}
          >
            Search Contacts
          </button>
          <button
            className={`toggle-button ${currentView === 'view' ? 'active' : ''}`}
            onClick={handleViewClick}
          >
            View Contact
          </button>
        </div>
        {currentView === 'search' ? (
          <div className="search-contacts">
            {loading ? (
              <p>Loading...</p>
            ) : contacts.length > 0 ? (
              <ul>
                {contacts.map(contact => (
                  <li key={contact._id}>
                    {contact.Name} ({contact.Email})
                  </li>
                ))}
              </ul>
            ) : (
              <p>No contacts found</p>
            )}
          </div>
        ) : (
          <div className="view-contact">
            <input
              type="text"
              placeholder="Enter email to view contact"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
            <button onClick={handleViewContact}>View</button>
            {loading ? (
              <p>Loading...</p>
            ) : selectedContact && selectedContact.length > 0 ? (
              <div>
                <p>Name: {selectedContact[0].Name || 'No Name Available'}</p>
                <p>Last Login: {selectedContact[0].LastLogin ? new Date(selectedContact[0].LastLogin).toLocaleString('en-US', { timeZone: 'America/New_York' }) : 'No Last Login Available'}</p>
              </div>
            ) : (
              <p>No contact found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactsBanner;
