import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './AdminApp.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Header from './Components/Header';
import SideBar from './Components/SideBar';
import AdminDashboard from './Components/AdminDashboard';
import Main from './Components/Main';
import RegisterDeliverable from './Components/RegisterDeliverable';
import ContactsBanner from './Components/ContactsBanner'; // Assuming you have a ContactsBanner component
import BlockUser from './Components/BlockUser.jsx'; // Import BlockUser component

function AdminApp() {
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const [pageIcon, setPageIcon] = useState('bi bi-grid');
  const [currentView, setCurrentView] = useState('Dashboard');
  const [showDeliverable, setShowDeliverable] = useState(false); // For RegisterDeliverable visibility
  const [showContacts, setShowContacts] = useState(false); // For ContactsBanner visibility
  const [showBlockUser, setShowBlockUser] = useState(false); // Manage BlockUser visibility

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/admin':
        setPageTitle('Dashboard');
        setPageIcon('bi bi-grid');
        break;
      case '/admin/orders':
        setPageTitle('Orders');
        setPageIcon('bi bi-basket');
        break;
      default:
        setPageTitle('Not Found');
        setPageIcon('bi bi-exclamation-circle');
        break;
    }
  }, [location.pathname]);

  const handleNavClick = (view) => {
    if (view === 'Deliverable') {
      setShowDeliverable(true);
      setShowContacts(false);
      setShowBlockUser(false);
    } else if (view === 'Contacts') {
      setShowContacts(true);
      setShowDeliverable(false);
      setShowBlockUser(false);
    } else if (view === 'Block User') {
      setShowBlockUser(true);
      setShowDeliverable(false);
      setShowContacts(false);
    } else {
      setCurrentView(view);
      setShowDeliverable(false);
      setShowContacts(false);
      setShowBlockUser(false);
    }
  };

  const handleCloseRegister = () => {
    setShowDeliverable(false); // Close RegisterDeliverable
  };

  const handleCloseContacts = () => {
    setShowContacts(false); // Close ContactsBanner
  };
  const handleCloseBlockUser = () => {
    setShowBlockUser(false); // Close BlockUser modal
  };

  return (
    <div className="admin-container">
      <Header className="header" />
      <SideBar onNavClick={handleNavClick} className="sidebar" />
      <div className="main-content">
        <Main pageTitle={pageTitle} pageIcon={pageIcon} />
        {showDeliverable && <RegisterDeliverable onClose={handleCloseRegister} />}
        {showContacts && <ContactsBanner onClose={handleCloseContacts} />}
        {showBlockUser && <BlockUser onClose={handleCloseBlockUser} />} {/* Render BlockUser component */}
        {!showDeliverable && !showContacts && !showBlockUser && (
          <Routes>
            <Route exact path="/admin" element={<AdminDashboard />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default AdminApp;
