import React from 'react';
import './Header.css';
import Logo from './Logo';
import SearchBar from './SearchBar';
import Nav from './Nav';
import PageTitle from './PageTitle';

const Header = ({ pageTitle, pageIcon }) => {
  return (
    <header className="header fixed-top" id="header">
      <div className="header-content d-flex align-items-center justify-content-between">
        <Logo />
        <PageTitle page={pageTitle} icon={pageIcon} />
        <SearchBar />
        <Nav />
      </div>
    </header>
  );
}

export default Header;
