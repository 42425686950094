import React, { useEffect, useState, useContext } from 'react';
import Header from '../Components/Header/Header';
import FlipBook from '../Components/FlipBook';
import axiosInstance from '../../axiosInstance';
import { UserContext } from '../../UserContext'; 
import {useLocation, useNavigate } from 'react-router-dom';
import AlertBanner from '../Components/Banner/AlertBanner';
import { AtomSpinner } from 'react-epic-spinners';



const API_URL = process.env.REACT_APP_API_URL;
const URL = `${API_URL}/api/convert`;
const SEND = `${API_URL}/api/sticky`;

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of Navigate

  const { user, setUser, setGoogleDriveID, googleDriveID, setGoogleDriveLink } = useContext(UserContext); 
  const [pdfUrl, setPdfUrl] = useState(''); 
  const [stickyNote, setStickyNote] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showBanner, setShowBanner] = useState(false);
  const [saveAndFeedback, setSaveAndFeedback] = useState(null);
  const [cancel, setCancel] = useState(null);
  const [hasSaved, setHasSaved] = useState(false); 
  const [loadingPdf, setLoadingPdf] = useState(true); // New state for loading


  useEffect(() => {
    const initialize = async () => {
      setLoadingPdf(true); // Set loading state to true
      const savedEmail = sessionStorage.getItem('userEmail');
      const savedGoogleDriveID = sessionStorage.getItem('googleDriveID');
      
      // Clear old PDF URL from sessionStorage to prevent using an expired one
      sessionStorage.removeItem('googleDriveLink');
      
      // Set user email from sessionStorage if not already set
      if (savedEmail && !user) {
        setUser({ email: savedEmail });
      }

      // Set Google Drive ID from sessionStorage if available
      if (savedGoogleDriveID) {
        setGoogleDriveID(savedGoogleDriveID);
      }

      const savedLink = sessionStorage.getItem('googleDriveLink');
      if (savedLink) {
        setPdfUrl(savedLink);
        setGoogleDriveLink(savedLink);
        setLoadingPdf(false); // Set loading to false once PDF is set

        return; // Exit early if link is found in session storage
      }

      if (user && user.email) {
        try {
          const response = await axiosInstance.get(`${URL}?email=${user.email}`, {
            headers: { 'Accept': 'application/json' }, // Ensure we expect JSON
          });

          const contentType = response.headers['content-type'];
          console.log('Dashboard content-type:', contentType); // Log content type


          if (contentType && contentType.includes('text/html')) {
            // If response is HTML, treat as session expired and redirect
            alert('Session expired. Redirecting to login page.');
            navigate('/login-signup'); // Redirect to login
            return;
          }
          if (response.data && response.data.useSample) {
            setPdfUrl('sample.pdf');
            setGoogleDriveLink('sample.pdf');
            return;
          }
          if (response.data && response.data.useSample) {
            setPdfUrl('sample.pdf');
            setGoogleDriveLink('sample.pdf');
          } else if (response.data && response.data.pdfEndpoint) {
            const pdfEndpoint = response.data.pdfEndpoint;
            setPdfUrl(pdfEndpoint);
            sessionStorage.setItem('googleDriveLink', pdfEndpoint);
            setGoogleDriveLink(pdfEndpoint);

            const extractedID = response.data.googleDriveID || '';
            if (extractedID) {
              setGoogleDriveID(extractedID);
              sessionStorage.setItem('googleDriveID', extractedID);
            }
          } else {
            console.error('PDF endpoint is missing in the response data.');
          }
        } catch (error) {
          if (error.response && (error.response.status === 410 || error.response.status === 404)) {
            alert('Session expired. Redirecting to login page.');
            navigate('/login-signup');
          } else {
            console.error('Error fetching PDF:', error);
          }
        } finally {
          setLoadingPdf(false); // Set loading to false once the request completes
        }
      }
    };

    initialize();
  }, [user, setUser, setGoogleDriveID, setGoogleDriveLink, navigate]);

      

            
  const saveStickyNote = async () => {
    if (!user || !user.email) {
      console.error('User email is not available.');
      alert('Failed to save feedback: Email missing.');
      return;
    }

    if (!googleDriveID) {
      console.error('Google Drive ID is missing.');
      alert('Failed to save your feedback: Document ID is not available.');
      return;
    }

    if (hasSaved) {
      console.log('saveStickyNote has already been called.');
      return;
    }

    try {
      const payload = {
        email: user.email,
        googleDriveID,
        stickyNote,
      };

      await axiosInstance.post(`${SEND}`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      alert('Your feedback has been saved successfully!');
      setHasSaved(true);
    } catch (error) {
      console.error('Error saving your feedback:', error);
      alert('Failed to save your feedback.');
    }
  };

  const handleHeaderClick = () => {
    return new Promise((resolve) => {
      if (location.pathname === '/dashboard' && stickyNote.length > 0) {
        setShowBanner(true);

        const handleSaveAndFeedback = async () => {
          await saveStickyNote();
          setShowBanner(false);
          resolve(true);
        };

        const handleCancel = () => {
          setShowBanner(false);
          resolve(false);
        };

        setSaveAndFeedback(() => handleSaveAndFeedback);
        setCancel(() => handleCancel);
      } else {
        resolve(true);
      }
    });
  };

  useEffect(() => {
    return () => {
      setHasSaved(false);
    };
  }, []);

  return (
    <div>
      <Header onHeaderClick={handleHeaderClick} />
      <div>
        {loadingPdf ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full screen height
          }}>
            <AtomSpinner color="#000000" loading={loadingPdf} size={50} />
          </div>
        ) : (
          <FlipBook
            pdfUrl={pdfUrl}
            stickyNote={stickyNote}
            setStickyNote={setStickyNote}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            googleDriveID={googleDriveID}
          />
        )}
      </div>
      <AlertBanner
        showBanner={showBanner}
        handleCancel={cancel}
        handleSaveAndFeedback={saveAndFeedback}
      />
    </div>
  );
};

export default Dashboard;
