// Mount.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './Mount.css';

const Mount = ({ children, className = '', ...props }) => {
  return (
    <div className={`containerMount ${className}`} {...props}>
      {children}
    </div>
    );
  };
  Mount.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Mount;
