import React from 'react';
import './Nav.css';
import { Link, useNavigate } from 'react-router-dom';

const Nav = ({ onHeaderClick }) => {
    const navigate = useNavigate();

    const handleToggleSidebar = () => {
        document.body.classList.toggle('toggle-sidebar');
    };
    const handleLinkClick = async (e, path) => {
        e.preventDefault();
    
        if (onHeaderClick) {
            const proceed = await onHeaderClick();
            if (proceed) {
                navigate(path);
            }
        } else {
            navigate(path); // Proceed normally if no onHeaderClick is provided
        }
    };
    
    return (
        <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
                <li>
                    <Link to="/login-signup" className="menu-icon" onClick={(e) => handleLinkClick(e, '/login-signup')}>
                        <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSidebar}></i>
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Nav;
