import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PriceContext } from '../Context/PriceContext';
import calendar_icon from '../Assets/calendar_icon.png';
import pen_paper_icon from '../Assets/pen_paper_icon.png';
import word_count_icon from '../Assets/word_count_icon.png';
import Drop from './Drop';
import './PriceCalculator.css';
import Mount from '../Mount';
import { Helmet } from 'react-helmet';

const PriceCalculator = () => {
  const { priceData, setPriceData } = useContext(PriceContext);
  const [wordcount, setWordcount] = useState(priceData.wordcount || '');
  const [deliveryTurnaround, setDeliveryTurnaround] = useState(priceData.deliveryTurnaround || '');
  const [documentType, setDocumentType] = useState(priceData.documentType || '');
  const navigate = useNavigate();

  const handleWordcountChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setWordcount(value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')); // Add commas
  }

  const handleDeliveryTurnaroundChange = (value) => {
    setDeliveryTurnaround(value);
  };

  const handleDocumentTypeChange = (value) => {
    setDocumentType(value);
  };

  const handleNewProjectClick = (e) => {
    e.preventDefault();
    if (wordcount && deliveryTurnaround && documentType) {
      setPriceData((prevData) => ({
        ...prevData,
        wordcount,
        deliveryTurnaround,
        documentType,
      }));
      navigate('/add-services');
    } else {
      alert("Please ensure all fields are correct before proceeding.");
    }
  };

  return (
    <Mount>
      <Helmet>
        <title>Price Calculator - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
        <div className="header">
          <div className="text">New Project</div>
          <div className="underline"></div>
        </div>
        <form onSubmit={handleNewProjectClick}>
          <div className="inputs">
            <div className="doubleColumn">
              <div className="wordCount">
                <img src={word_count_icon} id="word_count_icon" alt="" />
                <input type="text" placeholder='Word Count' value={wordcount} onChange={handleWordcountChange} />
              </div>
              <div className='Drop-DownOptions'>
                <Drop
                  className='DeliveryTurnaround' options={["Same Day (4-6hrs)", "Next Day (24hrs)", "Priority (72 hours)", "Standard (5-7 days)"]}
                  onSelect={handleDeliveryTurnaroundChange}
                  icon={calendar_icon}
                />
                <Drop
                  className='DocumentType'
                  options={["(EEOC) Equal Employment Opportunity Commission", "(MSPB) Merit System Protection Board", "(OSC) Office of Special Counsel", "(OWCP) Office of Worker's Compensation", "Affidavit", "Business Letter", "Cease & Desist", "Formal Grievance", "Formal Notice", "Government Resume", "Grant Proposal", "Informal Resolution Attempt", "Initial Filing (Administrative Proceeding)", "Letter of Inquiry", "Memorandum", "Modern Resume", "Motion", "Notice of Non-Compliance", "Pleading", "Professional Resume", "Statement of Fact", "Other-Document Type"]}
                  onSelect={handleDocumentTypeChange}
                  icon={pen_paper_icon}
                />
              </div>
            </div>
          </div>
         
          <div className="submit-container">
          <div className="link_one">
            
            <Link to="/add-services"><span>Additional Services </span></Link>
          </div>
            <button type="submit" className="submit" onClick={handleNewProjectClick}>
              New Project
            </button>
            <button type="button" className="submit gray" onClick={() => navigate('/login-signup')}>Existing Project</button>
          </div>
        </form>
      </div>
    </Mount>
  );
};

export default PriceCalculator;
