import React, { useEffect, useState, useContext } from 'react';
import './Drop.css';
import { PriceContext } from '../Context/PriceContext'; // Adjust the path as needed

const Drop = ({ options, onSelect, className, icon }) => {
  const { priceData, setPriceData } = useContext(PriceContext);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const defaultColor = '#d0dcdf'; // Adjust the color as needed
    const dropdown = document.getElementById(className);
    if (dropdown) {
      const defaultOption = dropdown.querySelector('option');
      if (defaultOption) {
        defaultOption.style.color = defaultColor;
      }
    }
  }, [className]);

  const calculateDeadline = (option) => {
    const now = new Date();
    const utcDate = new Date(now.toUTCString());
    let daysToAdd;
    switch (option) {
      case "Same Day (4-6hrs)":
        return formatToEST(utcDate);
      case "Next Day (24hrs)":
        daysToAdd = 1;
        break;
      case "Priority (72 hours)":
        daysToAdd = 3;
        break;
      case "Standard (5-7 days)":
        daysToAdd = 7;
        break;
      default:
        daysToAdd = 0;
    }
    utcDate.setDate(utcDate.getDate() + daysToAdd);
    return formatToEST(utcDate);
  };

  const formatToEST = (date) => {
    const options = { timeZone: 'America/New_York', year: 'numeric', month: 'long', day: 'numeric' };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date);
  };

  const handleChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    const deliveryDate = calculateDeadline(option);
    setPriceData({
      ...priceData,
      deliveryTurnaround: option,
      deadline: deliveryDate // Use 'deliveryDate' here
    });
    onSelect(option);
  };

  return (
    <div className={`Drop ${className}`}>
      <div className="input">
        <img src={icon} alt="" /> {/* Render the icon dynamically */}
        <select id={className} onChange={handleChange}>
          {/* Conditionally render default options based on the className */}
          {className === 'DocumentType' && (
            <option value="">Document Type</option>
          )}
          {className === 'DeliveryTurnaround' && (
            <option value="">Completion Date</option>
          )}
          {/* Render other options */}
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Drop;
