import React from 'react';
import './Logo.css';
import logo_silver from '../Assets/Images/logo_silver.png';

function Logo() {
    const handleToggleSidebar = () => {
        document.body.classList.toggle('toggle-sidebar');
    };

  return (
    <div className="logo">
        <a href="/" className="d-flex align-items-center">
         <img src={logo_silver} alt="Logo" />
         <span className="d-none d-lg-block"></span>
         </a>
         <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSidebar}>

         </i>
    </div>
    
  );
}

export default Logo
