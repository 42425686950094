import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
       <p className="Check_Out">
          "Witness the transformative power of conviction!"
        </p>
      
    </div> 
  )
}

export default Footer
