import React from 'react';
import './Main.css';
import PageTitle from './PageTitle';
import AdminDashboard from './AdminDashboard';

const Main = ({ pageTitle, pageIcon }) => {
  return (
    <main id="main" className="main">
      
      <AdminDashboard />
    </main>
  );
}

export default Main;
