import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; // Ensure this path is correct
import './SAT.css'; // Import CSS for SAT component

const SAT = () => {
  const [action, setAction] = useState("Digital SAT"); // Initial state is "Digital SAT"
  const navigate = useNavigate(); // Use navigate for navigation

  const handleHomeClick = () => {
    navigate('/home'); // Navigate to home page
  };

  const handleDigitalSATClick = () => {
    setAction("Digital SAT"); // Set action state to "Digital SAT" when clicked
  };

  const handleGEDClick = () => {
    setAction("G.E.D."); // Set action state to "G.E.D." when clicked
  };

  const handleBluebookLinkClick = (e) => {
    const confirmNavigation = window.confirm("You are about to navigate away from our site to a third-party service provider. Do you wish to continue?");
    if (!confirmNavigation) {
      e.preventDefault();
    }
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Digital SAT" ? "Digital SAT" : "Confidence"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
      <div className="header">
          <div className="text">{action === "Digital SAT" ? "Digital SAT" : "Guided Learning Activities"}</div>
          <div className="underline"></div>
        </div>
        
        <>
          {action === "Digital SAT" ? (
            <>
            <br /><br /><br />
              <h4>Did you know the Standard Admissions Test has gone digital?</h4>
              <br />
              <br />
              <p>Well, it has, and it's easier too, but don't take our word for it. Take the online practice exam for yourself, then use our free study guides to improve your next score!</p><br />
              <p style={{ textAlign: 'left' }}>
                
                <a 
                  href="https://bluebook.collegeboard.org/students/download-bluebook" 
                  className="link-with-space" 
                  onClick={handleBluebookLinkClick}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 Take the SAT and get immediate results.
                </a>
               
              </p>
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <br /><br /><br />
              <h4>
                Let our expert linguists show you all you need to know to get the highest score reasonably possible.
              </h4><br />
              <p style={{ textAlign: 'left' }}>
                Preparing for the SAT to study abroad, participate in a dual enrollment program, or enter the University of your choice in the United States can be challenging, but it doesn't have to be. 
              </p>
              <br />
      
              <p style={{ textAlign: 'left' }}>
                
                <Link to="/articles" className="link-with-space">Master independent study.</Link>
                
              </p>
            </div>
          )}
        </>
      </div>  
      <div className="submit-container">
        {action === "Digital SAT" ? (
          <>
            <div className="submit" onClick={handleHomeClick}>Go Back</div>
            <div className="submit gray" onClick={handleGEDClick}>Learn More</div>
          </>
        ) : (
          <>
            <div className="submit gray" onClick={handleDigitalSATClick}>Go Back</div>
            <Link to="https://bluebook.app.collegeboard.org/" className="submit">Take Test</Link>
          </>
        )}
      </div>
    </Mount>
  );
};

export default SAT;
