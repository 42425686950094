import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ClientApp from './client/ClientApp';
import AdminApp from './admin/AdminApp';
import UserProvider, { UserContext } from './UserContext';
import './App.css';
import Enter from './Enter';

const App = () => {
  const ProtectedRoute = ({ element, adminOnly }) => {
    const { user } = useContext(UserContext);
    if (adminOnly && user?.role !== 'admin') {
      return <Navigate to="/" replace />;
    }
    return user ? element : <Navigate to="/login-signup" replace />;
  };

  return (
    <UserProvider>
      <Routes>
        <Route path="/admin/*" element={<ProtectedRoute element={<AdminApp />} adminOnly={true} />} />
        <Route path="/enter/:email/:link" element={<Enter />} />
        <Route path="/*" element={<ClientApp />} />
      </Routes>
    </UserProvider>
  );
};

export default App;
