import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PriceContext } from '../Context/PriceContext';
import './AddServices.css';
import Mount from '../Mount';
import { Helmet } from 'react-helmet';

const AddServices = () => {
  const { priceData, setPriceData } = useContext(PriceContext);
  const [services, setServices] = useState(priceData.services || {});
  const [tooltip, setTooltip] = useState({ visible: false, text: '' });
  const navigate = useNavigate();

  useEffect(() => {
    setServices(priceData.services);
  }, [priceData.services]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setServices((prevServices) => ({
      ...prevServices,
      [id]: checked,
    }));
  };

  useEffect(() => {
    setPriceData((prevData) => ({
      ...prevData,
      services,
    }));
  }, [services, setPriceData]);

  const handleSubmitOrderClick = (e) => {
    e.preventDefault();
    navigate('/upload');
  };

  const handleMouseEnter = (event, service) => {
    setTooltip({
      visible: true,
      text: tooltips[service],
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, text: '' });
  };

  const tooltips = {
    DRR: "Choose this option to initiate Data Research & Retrieval. It includes any reasonable activity necessary to process your order or produce successful results on your project. This option should be chosen if your project requires detailed review and reporting on a specific topic or if your project necesitates the completion of additional tasks such as reviewing multimedia files, conducting specialized research, investigations, and/or web searches. This option does not involve drafting or generating written reports or responses.",
    Investigate: "Select this option if your project requires additional data researched, retrieved, or examined. Typically, clients choose this option to initiate a targeted investigation.",
    Research: "Select this option if your project requires additional data researched, retrieved, or examined. Typically, clients choose this option to initiate specialized research on a particular area of interest.",
    Design: "Select this option to initiate a project with a custom design, graphic, letterhead, or logo.",
    Review: "Select this option to initiate the review, research, examination, and/or retrieval of additional information. You may also choose this option to have supporting documentation that must be briefly reviewed.",
    Layout: "Choose this option for Grant Proposals, MSPB, or EEOC related projects. You may also choose this option if your project requires strict adherence to a legal, professional, or academic formatting standard.",
    Merge: "You may request to merge additional documents as enclosures to your main file or you may merge several documents into a single pdf for one FLAT RATE. File size may not exceed 10MB.",
    Audio: "Choose this option to submit supporting documentation (.mp3 files) that when combined, do not exceed 10 minutes.",
    Video: "Select this option if you will be uploading supporting documentation in the form of a video that does not exceed 1 hour in length.",
    Compress: "Select this option if you require a specific file size. For example, MSPB filings, pleadings, and upload cannot exceed 10MB. If your supporting documentation exceeds this limit, compression would be the best alternative to eliminating essential files.",
    Convert: "All projects are returned to the client as a .pdf; however should you desire an editable Photoshop, Adobe Illustrator, Microsoft Publisher or Microsoft Word file, please select this option so the file can be converted for your future use.",
    Consultation: "Select this option to have an audio-visual conference call scheduled within the next 72 hours.",
  };

  return (
    <Mount>
      <Helmet>
        <title>Add Services - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
        <header className="header">
          <div className="text">Premium Services</div>
          <div className="underline"></div>
        </header>
        <form onSubmit={handleSubmitOrderClick}>
          <div className="services">
            {Object.keys(services).map((service, index) => (
              <div
                className="service-item"
                key={service}
                onMouseEnter={(e) => handleMouseEnter(e, service)}
                onMouseLeave={handleMouseLeave}
              >
                <input
                  type="checkbox"
                  id={service}
                  checked={services[service]}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={service}>{service}</label>
              </div>
            ))}
            <div
              className={`tooltip ${tooltip.visible ? 'visible' : ''}`}
            >
              {tooltip.text}
            </div>
          </div>
          <div className="submit-container">
            <button type="submit" className="submit">
              Submit Order
            </button>
            <button type="button" className="submit gray" onClick={() => navigate('/price-calculator')}>
              Edit Order
            </button>
          </div>
        </form>
      </div>
    </Mount>
  );
};

export default AddServices;
