import React from 'react';

import profileImg from '../Assets/Images/profileImg.png'

const NavAvatar = () => {
  return (
   <li className="nav-item dropdown pe-3">
    <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#"
      data-bs-toggle="dropdown">
        <img src={profileImg} alt="Profile" className="rounded-circle" />
        <span className="d-none d-md-block dropdown-toggle ps-2">F. David</span>
    </a>
   </li>
  );
}

export default NavAvatar
