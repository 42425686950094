// ClientApp.jsx
import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserContext } from '../UserContext';
import './ClientApp.css';
import Home from './Pages/Home.jsx';
import LoginSignup from './Components/LoginSignup/LoginSignup.jsx';
import ContactUs from './Pages/ContactUs.jsx';
import PriceCalculator from './Components/PriceCalculator/PriceCalculator.jsx';
import { PriceProvider } from './Components/Context/PriceContext.js';
import ConfirmationPayment from './Components/ConfirmationPayment/ConfirmationPayment.jsx';
import AddServices from './Components/AddServices/AddServices.jsx';
import Upload from './Components/Upload/Upload.jsx';
import FlipBook from './Components/FlipBook.jsx';
import TestComponent from './Components/TestComponent.jsx';
import Privacy from './Components/Privacy/Privacy.jsx';
import Policies from './Pages/Policies.jsx';
import Blog from './Pages/Blog.jsx';
import Dashboard from './Pages/Dashboard.jsx';
import AboutUs from './Components/AboutUs/AboutUs.jsx';
import Hook from './Components/Hook/Hook.jsx';
import HookB from './Components/Hook/HookB.jsx';
import HookC from './Components/Hook/HookC.jsx';
import SAT from './Components/SAT/SAT.jsx';
import CGI from './Components/CGI/CGI.jsx';



const ClientApp = () => {
  const { signIn, signup, userEmail } = useContext(UserContext);


 
  return (
    <PriceProvider>
      <Routes>
        <Route path="/" element={<AboutUs />} />
        <Route path="/home" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/login-signup" element={<LoginSignup signIn={signIn} signup={signup} userEmail={userEmail} />} />
        <Route path="/price-calculator" element={<PriceCalculator />} />
        <Route path="/confirmation-payment" element={<ConfirmationPayment />} />
        <Route path="/add-services" element={<AddServices />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/hook" element={<Hook />} />
        <Route path="/hookb" element={<HookB />} />
        <Route path="/hookc" element={<HookC />} />
        <Route path="/sat" element={<SAT />} />
        <Route path="/cgi" element={<CGI />} />
        <Route path="/articles" element={<Blog />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/flipbook" element={<FlipBook  />} />
        <Route path="/test" element={<TestComponent />} />
      </Routes>
    </PriceProvider>
  );
};

export default ClientApp;
