import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL:', API_URL);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [googleDriveID, setGoogleDriveID] = useState(''); // Initialize Google Drive ID state
  const [googleDriveLink, setGoogleDriveLink] = useState(null); // Add googleDriveLink to context

  const navigate = useNavigate();

  const handleMagicLinkLogin = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const magicLink = urlParams.get('magicLink');

    if (email && magicLink) {
      try {
        const res = await axiosInstance.post(`${API_URL}/users/enter`, { email, magicLink }, { withCredentials: true });
        console.log('Response from server:', res.data);
        if (res.data.success) {
          localStorage.setItem('token', res.data.token);
          setUser({ email, role: res.data.role });

          sessionStorage.setItem('userEmail', email);
          sessionStorage.setItem('userRole', res.data.role);

          navigateBasedOnRole(res.data.role);
        } else {
          console.error('Authentication failed:', res.data.message);
        }
      } catch (error) {
        console.error('Error during sign-in:', error);
      }
    } else {
      // Check sessionStorage if email is missing from URL params
      const savedEmail = sessionStorage.getItem('userEmail');
      const savedRole = sessionStorage.getItem('userRole');

      if (savedEmail) {
        setUser({ email: savedEmail, role: savedRole });
        navigateBasedOnRole(savedRole);
      } else {
        console.error('Magic link or email not found in URL.');
      }
    } // <-- Missing closing bracket added here
  };

  const navigateBasedOnRole = (role) => {
    if (role === 'admin') {
      navigate('/admin');
    } else {
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log('URL Parameters:', Object.fromEntries(urlParams.entries()));
    handleMagicLinkLogin();
  }, []);

  const signIn = async (email, magicLink) => {
    console.log(`Sending login request for ${email} with magic link ${magicLink}`);
    try {
      const res = await axiosInstance.post(`${API_URL}/users/enter`, { email, magicLink }, { withCredentials: true });
      console.log('Response from server:', res.data);
      if (res.data.success) {
        localStorage.setItem('token', res.data.token);
        const user = { email, role: res.data.role };
        setUser(user);
        navigateBasedOnRole(res.data.role);
        return user; // Return the user object for further use
      } else {
        console.error('Authentication failed:', res.data.message);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, signIn, googleDriveLink, setGoogleDriveLink, googleDriveID, setGoogleDriveID }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
