import React, { useState, useEffect } from 'react';
import './BlockUser.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const BlockUser = ({ onClose }) => {
  const [searchEmail, setSearchEmail] = useState('');
  const [userStatus, setUserStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blockReason, setBlockReason] = useState('');
  const [blockDetails, setBlockDetails] = useState('');

  // Function to fetch user status
  const fetchUserStatus = async (email) => {
    if (email.trim()) {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/users/status`, {
          params: { email }
        });
        const status = response.data.status.toUpperCase(); // Convert status to uppercase
        setUserStatus(status); // Set user status based on backend response
        if (status === 'BLOCKED') {
          setBlockReason(response.data.reason || 'No reason provided');
          setBlockDetails(response.data.details || 'No details provided');
        }
      } catch (error) {
        console.error('Error fetching user status:', error);
        setUserStatus(null);
      }
      setLoading(false);
    } else {
      console.error('Email is required for search');
    }
  };

  // Effect to automatically fetch user status when a valid email is entered
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(searchEmail)) {
      fetchUserStatus(searchEmail);
    } else if (searchEmail.trim() === '') {
      setUserStatus(null); // Reset user status if the search field is cleared
    }
  }, [searchEmail]); // Trigger when searchEmail changes

  const handleBlockUser = async () => {
    if (blockReason && blockDetails) { // Check if all required fields are filled
      try {
        await axios.post(`${API_URL}/users/block`, { 
          email: searchEmail, 
          reason: blockReason,
          details: blockDetails 
        });
        setUserStatus('BLOCKED');
      } catch (error) {
        console.error('Error blocking user:', error);
      }
    } else {
      console.error('Block reason and details are required.');
    }
  };

  const handleUnblockUser = async () => {
    try {
      await axios.post(`${API_URL}/users/unblock`, { email: searchEmail });
      setUserStatus('ACTIVE');
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  };
  const handleEmailChange = (e) => {
    setSearchEmail(e.target.value.toLowerCase());
  };
  

  return (
    <div className="block-user">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>x</button>
        
        {/* Toggle Buttons for Block and Unblock */}
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${userStatus === 'ACTIVE' ? 'active' : 'inactive'}`}
            onClick={handleBlockUser}
            disabled={
              !searchEmail.trim() || 
              userStatus !== 'ACTIVE' || 
              !blockReason || 
              !blockDetails
            } // Disable block button unless all fields are filled
          >
            Block
          </button>
          <button
            className={`toggle-button ${userStatus === 'BLOCKED' ? 'active' : 'inactive'}`}
            onClick={handleUnblockUser}
            disabled={!searchEmail.trim() || userStatus !== 'BLOCKED'}
          >
            Unblock
          </button>
        </div>

        {/* Search User Section */}
        <div className="search-user">
          <input
            type="text"
            placeholder="Enter user email"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value.toLowerCase())}
          />
        </div>

        {/* User Status Display and Actions */}
        {loading ? (
          <p>Loading...</p>
        ) : (
          userStatus && (
            <div className="user-status">
              <p className={userStatus === 'ACTIVE' ? 'active-status' : userStatus === 'BLOCKED' ? 'blocked-status' : ''}>
    <strong>{userStatus}</strong>
  </p>
              {userStatus === 'ACTIVE' ? (
                <>
                  <select 
                    value={blockReason} 
                    onChange={(e) => setBlockReason(e.target.value)}
                  >
                    <option value="">Select Reason</option>
                    <option value="Failure to Adhere to Terms & Conditions">Failure to Adhere to Terms & Conditions</option>
                    <option value="Policy Violation">Policy Violation</option>
                    <option value="Suspicious or Malicious Activity">Suspicious or Malicious Activity</option>
                  </select>
                  <textarea 
                    placeholder="Enter details of the violation"
                    value={blockDetails}
                    onChange={(e) => setBlockDetails(e.target.value)}
                    maxLength={750}
                  />
                </>
              ) : (
                <>
                  <div><p><strong>{blockReason}</strong></p></div>
                  <div className="block_details"><p>{blockDetails}</p></div>
                </>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BlockUser;
