import React from 'react';

const NavItem = ({ nav, onClick }) => {
  return (
    <li className="nav-item" key={nav._id} onClick={onClick}>
      <a className="nav-link collapsed" href="#">
        <i className={nav.icon}></i>
        <span>{nav.name}</span>
      </a>
    </li>
  );
};

export default NavItem;
