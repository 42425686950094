import React from 'react';
import './PageTitle.css';

const PageTitle = ({ page, icon }) => {
  return (
    <div className="pagetitle">
      <nav>
        <ol className="breadcrumb custom-breadcrumb">
         <li className="breadcrumb-item">  
          </li>
          <li className="breadcrumb-item active pagetitle-item">
          <a href="/">
            <i className={icon}></i> {/* Display the appropriate icon */} </a>
            <h1>{page}</h1>
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default PageTitle;
