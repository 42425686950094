// RegisterDeliverable.js

import React, { useState, useEffect } from 'react';
import './RegisterDeliverable.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const RegisterDeliverable = ({ onClose }) => {
  const [initialCost, setInitialCost] = useState('');
  const [finalCost, setFinalCost] = useState('');
  const [balanceDue, setBalanceDue] = useState('');
  const [currentView, setCurrentView] = useState('register');
  const [searchDeliverable, setSearchDeliverable] = useState(null);
  const [searchEmail, setSearchEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [testResponse, setTestResponse] = useState(null);

  const handleInitialCostChange = (e) => {
    const value = e.target.value;
    setInitialCost(value);
    updateBalanceDue(value, finalCost);
  };

  const handleFinalCostChange = (e) => {
    const value = e.target.value;
    setFinalCost(value);
    updateBalanceDue(initialCost, value);
  };

  const updateBalanceDue = (initial, final) => {
    const initialCostValue = parseFloat(initial) || 0;
    const finalCostValue = parseFloat(final) || 0;
    setBalanceDue(finalCostValue - initialCostValue);
  };

  const fetchSearchDeliverable = async (email) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/deliverable`, {
        params: { email }
      });
      const matchedData = response.data;
      setSearchDeliverable(matchedData);
    } catch (error) {
      console.error('Error fetching deliverable data:', error);
      setSearchDeliverable(null);
    }
    setLoading(false);
  };

  const handleRegisterFormSubmit = async () => {
    const form = document.querySelector('#register-form');
    if (form.checkValidity()) {
      const formData = new FormData(form);
      const data = Object.fromEntries(formData.entries());
  
      console.log('Form data:', data); // Log the form data
  
      try {
        const response = await axios.post(`${API_URL}/api/deliverable`, data);
        console.log('Submission successful:', response.data);
        onClose();
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
      }
    } else {
      form.reportValidity();
    }
  };
  
  const handleSearchFormSubmit = async () => {
    if (searchEmail.trim()) {
      await fetchSearchDeliverable(searchEmail);
    } else {
      console.error('Email is required for search');
    }
  };

  const handleToggleClick = (view) => {
    if (currentView !== view) {
      setCurrentView(view);
    } else {
      if (view === 'register') {
        handleRegisterFormSubmit();
      } else if (view === 'search') {
        handleSearchFormSubmit();
      }
    }
  };

  useEffect(() => {
    if (currentView === 'search' && searchEmail) {
      fetchSearchDeliverable(searchEmail);
    }
  }, [currentView, searchEmail]);

   // Test endpoint function
   const testEndpoint = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/test`, { message: 'Hello, server!' });
      setTestResponse(response.data.message);
    } catch (error) {
      console.error('Error testing endpoint:', error);
      setTestResponse('Error testing endpoint');
    }
  };
  const handleEmailChange = (e) => {
    setSearchEmail(e.target.value.toLowerCase());
  };
  

  return (
    <div className="register-deliverable">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>x</button>
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${currentView === 'register' ? 'active' : ''}`}
            onClick={() => handleToggleClick('register')}
          >
            Register Submission
          </button>
          <button
            className={`toggle-button ${currentView === 'search' ? 'active' : ''}`}
            onClick={() => handleToggleClick('search')}
          >
            Search Deliverable
          </button>
        </div>
        {currentView === 'register' ? (
          <form id="register-form">
            <input type="text" name="email" placeholder="Email" required />
            <input type="url" name="googleDriveLink" placeholder="Google Drive Link" required />
            <select name="projectType" required>
              <option value="" disabled selected>Project Type</option>
              <option value="EEOC">Equal Employment Opportunity Commission (EEOC)</option>
              <option value="MSPB">Merit System Protection Board (MSPB)</option>
              <option value="OSC">Office of Special Counsel (OSC)</option>
              <option value="OWCP">Office of Worker's Compensation (OWCP)</option>
              <option value="Affidavit">Affidavit</option>
              <option value="Business Letter">Business Letter</option>
              <option value="Cease & Desist">Cease & Desist</option>
              <option value="Formal Grievance">Formal Grievance</option>
              <option value="Formal Notice">Formal Notice</option>
              <option value="Government Resume">Government Resume</option>
              <option value="Grant Proposal">Grant Proposal</option>
              <option value="Informal Resolution Attempt">Informal Resolution Attempt</option>
              <option value="Initial Filing">Initial Filing (Administrative Proceeding)</option>
              <option value="Letter of Inquiry">Letter of Inquiry</option>
              <option value="Memorandum">Memorandum</option>
              <option value="Modern Resume">Modern Resume</option>
              <option value="Motion">Motion</option>
              <option value="Notice of Non-Compliance">Notice of Non-Compliance</option>
              <option value="Pleading">Pleading</option>
              <option value="Professional Resume">Professional Resume</option>
              <option value="Statement of Fact">Statement of Fact</option>
              <option value="Other">Other</option>
            </select>
            <div className="date-container">
              <label className="date-label">Completion Date</label>
              <input 
                type="date" 
                name="completionDate" 
                min={new Date().toISOString().split("T")[0]}  // This sets the minimum date to today
                onFocus={(e) => e.target.previousSibling.style.display = 'none'}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.previousSibling.style.display = 'block';
                  }
                }}
                required 
              />
            </div>

            <div className="date-container">
              <label className="date-label">Deadline</label>
              <input 
                type="date" 
                name="deadline" 
                onFocus={(e) => e.target.previousSibling.style.display = 'none'}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.previousSibling.style.display = 'block';
                  }
                }}
                required 
              />
            </div>

            <select name="preparer" required>
              <option value="" disabled selected>Prepared By</option>
              <option value="Ghostwriter">Ghostwriter</option>
              <option value="Independent Creator">Independent Creator</option>
              <option value="3D Artist/Animator">3D Artist/Animator</option>
            </select>
            <div className="cost-container">
              <input 
                type="text" 
                name="initialCost" 
                placeholder="Amount Paid" 
                value={initialCost}
                onChange={handleInitialCostChange}
                required 
              />
              <input 
                type="text" 
                name="finalCost" 
                placeholder="Final Cost" 
                value={finalCost}
                onChange={handleFinalCostChange}
                required 
              />
            </div>
            <input 
              type="text" 
              name="balanceDue" 
              placeholder="Amount Due" 
              value={balanceDue} 
              readOnly 
              required 
            />
          </form>
        ) : (
          <div className="search-deliverable">
            <input
              type="text"
              placeholder="Search by email"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value.toLowerCase())}
            />

            {loading ? (
              <p>Loading...</p>
            ) : searchDeliverable ? (
              <div className="search-deliverable-return">
                <input type="url" name="googleDriveLink" placeholder="Google Drive Link" value={searchDeliverable.googleDriveLink} readOnly />
                <select name="projectType" value={searchDeliverable.projectType} disabled>
                  <option value="">{searchDeliverable.projectType}</option>
                </select>
                <input 
                  type="date" 
                  name="completionDate" 
                  placeholder="Completion Date" 
                  value={searchDeliverable.completionDate || ''} // Ensure value is in YYYY-MM-DD format
                  readOnly 
                />
                <select name="preparer" value={searchDeliverable.preparer} disabled>
                  <option value="">{searchDeliverable.preparer}</option>
                </select>
                <div className="cost-container">
                  <input 
                    type="text" 
                    name="initialCost" 
                    placeholder="Initial Cost" 
                    value={searchDeliverable.initialCost}
                    readOnly 
                  />
                  <input 
                    type="text" 
                    name="finalCost" 
                    placeholder="Final Cost" 
                    value={searchDeliverable.finalCost}
                    readOnly 
                  />
                </div>
                <input 
                  type="text" 
                  name="balanceDue" 
                  placeholder="Balance Due" 
                  value={searchDeliverable.balanceDue} 
                  readOnly 
                />
                <div>
                <a href={searchDeliverable.googleDriveLink} target="_blank" rel="noopener noreferrer">VIEW</a></div>
              </div>
            ) : (
              <p>No data found</p>
            )}
          </div>
        )}
        <div className="test-endpoint">
          <button onClick={testEndpoint}>Test Endpoint</button>
          {testResponse && <p>Test Response: {testResponse}</p>}
        </div>
      </div>
    </div>
  );
};

export default RegisterDeliverable;
