import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CardFilter.css';

const API_URL = process.env.REACT_APP_API_URL;

function CardFilter({ filterChange, options, cardName }) {
  const [individuals, setIndividuals] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchIndividuals = async () => {
      console.log(`Card name: ${cardName}`); // Log to confirm cardName
      if (cardName === 'Statistics' && !dataFetched) {
        try {
          console.log('Making API request to fetch individuals');
          const response = await axios.get(`${API_URL}/stars/statistics?filter=individual`);
          console.log('API response:', response.data); // Log the response

          const data = response.data;

          if (data.type === 'individual' && Array.isArray(data.individuals)) {
            console.log('Fetched individuals:', data.individuals);
            setIndividuals(data.individuals);
          } else {
            console.warn('Unexpected data format:', data);
            setIndividuals([]);
          }
          setDataFetched(true);
        } catch (error) {
          console.error('Error fetching individuals', error);
          setIndividuals([]);
        }
      }
    };

    fetchIndividuals();
  }, [cardName, dataFetched]);

  const handleFilterChange = (option) => {
    console.log('Filter option clicked:', option);
    filterChange(option);
  };

  return (
    <div className="filter">
      <a className="icon" href="#" data-bs-toggle="dropdown">
        <i className="bi bi-three-dots" style={{ color: '#ae1893' }}></i>
      </a>
      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <li className="dropdown-header text-start">
          <h6>Filter</h6>
        </li>
        {options.map((option, index) => (
          <li key={index}>
            <a className="dropdown-item" onClick={() => handleFilterChange(option)}>
              {option}
            </a>
          </li>
        ))}
        {cardName === 'Statistics' && individuals.length > 0 && (
          <>
            <li className="dropdown-divider"></li>
            {individuals.map((individual, index) => (
              <li key={index}>
                <a className="dropdown-item" onClick={() => handleFilterChange(individual)}>
                  {individual}
                </a>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
}

export default CardFilter;
