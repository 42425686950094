import React from 'react';
import './MainContent.css';
import { Link } from 'react-router-dom';
import Bubble1 from '../Assets/Bubble_1.png';
import Bubble2 from '../Assets/Bubble_2.png';
import Bubble3 from '../Assets/Bubble_3.png';
import Bubble4 from '../Assets/Bubble_4.png';
import Bubble5 from '../Assets/Bubble_5.png';

const MainContent = () => {
  return (
    <div className="mainContent">
      <div className="header">
        <div className="text"></div>
      </div>

      <div className="container">
        <div className="box">
          <Link to="/hook"><img src={Bubble1} className="Bubble_1" alt="" /></Link>
        </div>
        <div className="box">
          <Link to="/hookb"><img src={Bubble2} className="Bubble_2" alt="" /></Link>
        </div>
        <div className="box">
          <Link to="/hookc"><img src={Bubble3} className="Bubble_3" alt="" /></Link>
        </div>
        <div className="box">
          <Link to="/sat"><img src={Bubble4} className="Bubble_4" alt="" /></Link>
        </div>
        <div className="box">
          <Link to="/cgi"><img src={Bubble5} className="Bubble_5" alt="" /></Link>
        </div>
      </div>

      
    </div>
  );
};

export default MainContent;
