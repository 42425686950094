import React from 'react';
import './Header.css';
import Logo from '../Logo/Logo';
import Nav from './Nav';

const Header = ({ onHeaderClick }) => {
  return (
    <header className="header fixed-top" id="header">
      <div className="header-content d-flex align-items-center">
        <Logo onHeaderClick={onHeaderClick} />
        <Nav onHeaderClick={onHeaderClick} />
      </div>
    </header>
  );
}

export default Header;
