import React from 'react';
import './AlertBanner.css';

const AlertBanner = ({ showBanner, handleCancel, handleSaveAndFeedback,children }) => {
  if (!showBanner) return null;

  return (
    <div className="banner">
      <button className="cancel-button" onClick={handleCancel}>X</button>
      <button className="save-button" onClick={handleSaveAndFeedback}>Save</button>
      <div className="banner-content">
        {children}
      </div>
    </div>
  );
};


export default AlertBanner;
